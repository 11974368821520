<template>
  <div class="textarea">
		<textarea type="text" class="textarea__input" :id="id" :name="id" v-model="textarea" :placeholder="placeholder" />
		<label :for="id" :class="['textarea__label',{'textarea__label--error' : error}]">{{label}}</label>
	</div>
</template>

<script>
export default {
	props: {
		label: String,
		id: String,
		placeholder:  {
      type: String,
      default: ' ',
    },
		error: Boolean,
	},
	data(){
		return{
      textarea: ''
		}
	},
  watch: {
    textarea: function() {
      this.$emit("textarea", this.textarea);
    }
  },
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.textarea{
	width: var(--textarea-width);
	margin-bottom: var(--textarea-margin-bottom);
	margin-top: var(--textarea-margin-top);
	position: relative;
}
.textarea__label{
	font-family: var(--textarea-label-font-family);
	color: var(--textarea-label-color);
	font-size: var(--textarea-label-font-size);
	font-weight: var(--textarea-label-font-weight);
	margin-left: var(--textarea-label-margin-left);
	bottom: var(--textarea-label-bottom);
	transition: var(--textarea-label-transition);
	position: var(--textarea-label-position);
	display: block;
	z-index: 1;
}
.textarea__label--error{
	color: var(--textarea-label-error-color);
}
.textarea__input{
	font-family: var(--textarea-input-font-family);
	color: var(--textarea-input-color);
	background: var(--textarea-input-background);
	font-size: var(--textarea-input-font-size);
	width: var(--textarea-input-width);
	padding: var(--textarea-input-padding);
	border-top: var(--textarea-input-border-top);
	border-right: var(--textarea-input-border-right);
	border-bottom: var(--textarea-input-border-bottom);
	border-left: var(--textarea-input-border-left);
	margin-bottom: var(--textarea-input-margin-bottom);
	line-height: var(--textarea-input-line-height);
	height: var(--textarea-input-height);
	resize: none;
}
.textarea__input:focus-visible{
	outline: none;
}
.textarea__input:focus + .textarea__label{
	bottom: var(--textarea-focus-label-bottom);
	font-size: var(--textarea-focus-label-font-size);
}
.textarea__input:not(:placeholder-shown) + .textarea__label{
	bottom: var(--textarea-focus-label-bottom);
	font-size: var(--textarea-focus-label-font-size);
}
</style>

<!-- Used in : AR -->